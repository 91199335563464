<template>
  <div :class="cellClass" @click="(item.type === 'checkbox' && !item.tooltip) || isIgnored ? '' : emitClick(item)" :style="item && item.style ? item.style : ''">
    <template v-if="cellType === 'text'">
      <span>
        {{ isHeader ? $t(item) : $t(item) || $n(item) }}
      </span>
    </template>
    <template v-else-if="cellType === 'icon'">
      <Tooltip v-if="item.tooltip" :text="item.tooltip.text" :texts="item.tooltip.texts" :boldText="item.tooltip.boldText">
        <img v-if="iconToShow" :src="iconToShow" :alt="item.icon" />
        <template v-else>{{ item.icon }}</template>
      </Tooltip>
      <template v-else>
        <img v-if="iconToShow" :src="iconToShow" :alt="item.icon" />
        <template v-else>{{ item.icon }}</template>
      </template>
    </template>
    <template v-else-if="cellType === 'multiple-icons'">
      <template v-for="(currItem, idx) in item">
        <Tooltip :key="idx" v-if="currItem.tooltip" :text="currItem.tooltip.text" :boldText="currItem.tooltip.boldText">
          <img @click.stop="emitClick(currItem)" class="action" :src="require(`@/assets/${currItem.icon}.svg`)" :alt="currItem.icon" />
        </Tooltip>
        <template v-else>
          <img :key="idx" @click.stop="emitClick(currItem)" class="action" :src="require(`@/assets/${currItem.icon}.svg`)" :alt="currItem.icon" />
        </template>
      </template>
    </template>
    <template v-else-if="isFlagActions">
      <Tooltip :text="$t('update')">
        <RestartUpdateBtn @toggleFlag="toggleFlag" :isUpdateBtn="true" :flag="{ name: 'is_update', isOn: item.is_update }" />
      </Tooltip>

      <Tooltip :text="$t('restart')">
        <RestartUpdateBtn @toggleFlag="toggleFlag" :isRestartBtn="true" :flag="{ name: 'is_restart', isOn: item.is_restart }" />
      </Tooltip>

      <template v-for="(flagValue, flagName, idx) in item.user_flags">
        <Tooltip v-if="flagName" :key="flagName + idx" :texts="getFlagTooltipTexts(flagName)">
          <IOSSwitchButton @toggleFlag="toggleFlag" :userFlag="{ name: flagName, isOn: flagValue }" :sensorFlag="{ name: flagName, isOn: item.sensor_flags[flagName] }"> </IOSSwitchButton>
        </Tooltip>
      </template>
    </template>
    <template v-else-if="cellType === 'date'">{{ isHeader ? $t(item) : item.text }}</template>
    <template v-else-if="cellType === 'battery'">
      <Tooltip :text="item.power_source || 'N/A'">
        <Battery :percentage="item.battery" />
      </Tooltip>
    </template>
    <template v-else-if="cellType === 'deviceStatus'">
      <DeviceStatus :status="item.level.value" :tooltipTxt="item.level.info ? item.level.info : ''" :isError="isError(item.level.value)" />
    </template>
    <template v-else-if="cellType === 'doubleDeviceStatus'">
      <DeviceStatus :status="`${item.level.value.up.percent}`" :tooltipTxt="item.level.value.up.info ? item.level.value.up.info : ''" :isError="isError(item.level.value.up.percent)" />
      <DeviceStatus :status="`${item.level.value.down.percent}`" :tooltipTxt="item.level.value.down.info ? item.level.value.down.info : ''" :isError="isError(item.level.value.down.percent)" />
    </template>
    <template v-else-if="cellType === 'checkbox'">
      <Checkbox class="cell-checkbox" :value="item.value" @input="emitClick" />
    </template>
    <template v-else-if="cellType === 'text-with-icon'">
      <Tooltip v-if="item.tooltip" :text="item.tooltip.text" :texts="item.tooltip.texts" :boldText="item.tooltip.boldText" :isCapitalized="item.tooltip.isCapitalized">
        <img v-show="showActions" v-if="iconToShow" :src="iconToShow" :alt="item.icon" />
      </Tooltip>
      {{ $t(item.text) }}
    </template>
  </div>
</template>

<script>
import Tooltip from '../Tooltip'
import Battery from '@/modules/devices/components/Battery'
import DeviceStatus from '@/modules/devices/components/DeviceStatus'
import Checkbox from '../Checkbox'
import IOSSwitchButton from '@/modules/common/components/IOSSwitchButton'
import RestartUpdateBtn from '@/modules/common/icons/RestartUpdateBtn'

export default {
  props: {
    /** icon property should be the path from assets folder to the icon
     * @typedef {{
     *  icon: String;
     *  tooltip?: { boldText: String, text: String };
     *  style?: Object;
     *  type: String;
     * }} IconItem
     * @typedef {{
     *  text: String;
     *  date: Date;
     * }} DateItem
     * @typedef {{
     *  value: boolean;
     *  type: 'checkbox'
     *  action: string;
     * }} Checkbox
     * @type {{new (): String | Number | IconItem | IconItem[] | DateItem | Checkbox} */
    item: { type: String | Number | Object, required: false },
    isHeader: { type: Boolean, default: false },
    showActions: { type: Boolean, default: false },
    isActive: { type: Boolean, default: false },
    isIgnored: { type: Boolean, default: false }
  },
  data() {
    return {
      sortActive: false
    }
  },
  methods: {
    emitClick(event) {
      if (this.item.type === 'checkbox') {
        this.$emit('checkboxClick', { type: this.item.action })
        return
      }

      if (event.type !== 'click') {
        this.sortActive = !this.sortActive
        this.$emit('cellClick', event, this.sortActive)
      } else {
        this.$emit('cellClick', this.item, this.sortActive)
      }
    },
    isError(level) {
      if (this.isCount) return true
      else if (level === 'err') return true
    },
    setActionFlags(actionFlags) {
      this.$emit('setActionFlags', actionFlags)
    },
    toggleFlag(flag) {
      const actionFlags = this.item
      this.$emit('toggleFlag', flag, actionFlags)
      this.setActionFlags(actionFlags)
    },
    getFlagTooltipTexts(flagName) {
      const texts = []
      flagName = flagName.split('is_')[1]
      texts.push(this.$t(flagName))
      if (flagName === 'blink') texts.push('*' + this.$t('drains_battery'))
      return texts
    }
  },
  computed: {
    cellType() {
      const { item } = this
      if (typeof item === 'string' || typeof item === 'number') return 'text'
      else if (item !== null && Array.isArray(item)) return 'multiple-icons'
      else if (item !== null && typeof item === 'object') {
        if ('text' in item && 'icon' in item) return 'text-with-icon'
        if (item.icon) return 'icon'
        else if (item.date) return 'date'
        else if (item.battery) return 'battery'
        else if (item.level && typeof item.level.value === 'object') return 'doubleDeviceStatus'
        else if (item.level && item.level.value) return 'deviceStatus'
        else if (item.type === 'checkbox') return 'checkbox'
      } else return ''
      return ''
    },
    iconToShow() {
      let icon
      try {
        icon = require(`@/assets/${this.item.icon}.svg`)
      } catch (err) {
        return false
      }
      return icon
    },
    isFlagActions() {
      if (!this.item) return false
      return this.item.user_flags && this.item.sensor_flags
    },
    cellClass() {
      const { cellType, isHeader, sortActive } = this
      return {
        'table-cell': true,
        'sort-active': sortActive && isHeader,
        [`${cellType}-cell`]: true,
        icon: cellType === 'icon',
        action: cellType === 'checkbox'
      }
    }
  },
  watch: {
    isActive: {
      handler: function(val, oldValue) {
        if (val) this.emitClick('pad')
      }
    }
  },
  components: { Tooltip, Battery, DeviceStatus, Checkbox, IOSSwitchButton, RestartUpdateBtn }
}
</script>

<style lang="scss" scoped>
.table-cell {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;

  &.text-cell {
    overflow: hidden;
    > span {
      text-overflow: ellipsis;
      overflow: hidden;
      padding-bottom: 10px;
      margin-bottom: -10px;
      white-space: nowrap;
      &:hover {
        overflow: visible;
        white-space: normal;
      }
    }
  }

  &.action {
    max-width: 50%;
    height: 100%;
    margin: 0 auto;
    visibility: hidden;
  }

  cursor: pointer;
  &.sort-active {
    font-weight: 700;
  }
  &.icon {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  .action {
    width: 1.1vw;
    margin: 0 8px;
  }

  .cell-checkbox {
    margin-bottom: 20px;
  }
}

.table-row:hover {
  .action {
    visibility: unset;
  }
}
</style>
